<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">融资服务</div>
      <div class="h2">企业服务 招商引资</div>
<!--      <div>-->
<!--        <el-input placeholder="请输入内容" v-model="keyword" style="width: 600px;border-radius: 15px" class="input-with-select">-->
<!--        </el-input>-->
<!--      </div>-->
    </div>
    <div class="content">
      <div class="flex top">
        <div class="left shou flex-1">
          <el-image  class="curp" :src="recommendList[0].image" @click="goto(recommendList[0].link)"></el-image>
        </div>
        <div class="right flex-1"><el-image  class="curp" :src="recommendList[1].image" @click="goto(recommendList[1].link)"></el-image></div>
      </div>
      <div class="flex top">
        <div class="left flex-1">
          <el-image  class="curp" :src="recommendList[2].image" @click="goto(recommendList[2].link)"></el-image>
        </div>
        <div class="left flex-1">
          <el-image  class="curp" :src="recommendList[3].image" @click="goto(recommendList[3].link)"></el-image>
        </div>
        <div class="right flex-1">
          <el-image   class="curp" :src="recommendList[4].image" @click="goto(recommendList[4].link)"></el-image>
        </div>
      </div>
      <div class="title">特色专区</div>
      <div class="">
        <el-carousel :interval="2000" type="card" height="230px">
          <el-carousel-item v-for="item in featureList" :key="item">
            <el-image  class="curp" :src="item.image" @click="goto(item.link)"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="title">重点项目</div>
      <div style="overflow: hidden">
        <div class="zhongdian curp"  v-for="(li,index) in emphasisList">
          <el-image  :src="li.image" @click="goto(li.link)"></el-image>
        </div>
      </div>
      <div class="title">热门推荐</div>
      <div class="rmtj" >
        <div class="lists curp" v-for="(item,index) in hotRecommendList">
          <el-image :src="item.image" @click="goto(item.link)"></el-image>
        </div>
      </div>
      <div style="text-align: center;margin: 30px 0">
        <el-button type="warning" style="width: 200px" @click="goto(datas.hotRecommendMoreLink)">查看更多</el-button>
      </div>
    </div>
    <div  class="bgf">
      <div class="bottom">
        <div class="flex news curp" v-for="(li,index) in newMessageList" @click="goto(li.link)">
          <div class="time">
            <div class="th1">2023</div>
            <div>07/31</div>
          </div>
          <div class="flex-1">
            <div class="ne1">【信易贷】金融惠企 服务赋能——“XXXX”系列中小企业恳谈会顺举行</div>
            <div class="ne2">来源：信用上海</div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center;padding: 20px">
      <el-button style="width: 200px" @click="goto(datas.newMessageMoreLink)">更多资讯</el-button>
    </div>
  </div>

</template>

<script>
export default {
  name: "jrfw",
  data(){
    return {
      datas:'',
      recommendList:[],
      featureList:[],
      emphasisList:[],
      hotRecommendList:[],
      newMessageList:[]
    }
  },
  created() {
    this.loadconfig()
  },
  methods:{
    loadconfig(){
      this.$api.financing.financingindex().then((res)=>{
        this.datas=res.data
        this.recommendList=res.data.recommendList
        this.featureList=res.data.featureList
        this.emphasisList=res.data.emphasisList
        this.hotRecommendList=res.data.hotRecommendList
        this.newMessageList=res.data.newMessageList
      })
    },
    goto(link){
      if(link){
        window.open(link,'_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zchjs{
  background: #fff;
}
.curp{
  cursor: pointer;
}
.bgf{
  background: #f5f5f5;
  padding: 20px;
}
.title{
  text-align: center;
  font-size: 20px;
  font-weight: 550;
  margin:50px 0;
}
.zhongdian{
  margin: 15px;
  float: left;
  width: 556px;height: 230px;
}
.bottom{
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  .time{
    width: 75px;
    height: 75px;
    margin-right: 25px;
    text-align: center;
    background: #F3F3F3;
    border-radius: 4px;
    .th1{
      font-size: 16px;
      font-weight: bolder;
      line-height: 30px;
      margin-top: 10px;
    }
  }
  .news{
    background: #fff;
    border-radius: 4px;
    width: 480px;
    padding: 28px;
    margin: 10px 20px;
    float: left;
    .ne1{
      font-weight: bolder;
    }
    .ne2{
      font-size: 12px;
      color: #8A8A8A;
      margin-top: 20px;
    }
  }
}
.header{
  height: 220px;
  background: url("../../assets/banner/jrfw.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 0 auto;
  margin-top: 62px;
  .top{
    color:#D3A23A ;
    text-align: center;
    line-height: 230px;
    font-size: 18px;
    margin-bottom: 50px;
    .shou{
      line-height: 30px;
      background: #334C66;
      color: #fff;
    }
    .left{
      height: 230px;
      margin: 0 10px;
    }
    .right{
      height: 230px;
      margin: 0 10px;
    }
  }
  .lunbo{
    width: 586px;
    height: 100%;
    border: 1px solid #ddd;
    text-align: center;
    line-height: 230px;
  }
  .rmtj{
   overflow: hidden;
    text-align: center;
    .lists{
      width: 255px;
      height: 300px;
      margin: 20px;
      float: left;
    }
  }
}
</style>